import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Alert } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Heading } from '@twilio-paste/heading';
import { Text } from '@twilio-paste/text';
import { Truncate } from '@twilio-paste/truncate';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import Changelog from '@twilio-paste/truncate/CHANGELOG.md';
import { SidebarCategoryRoutes } from '../../../constants';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/truncate"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/truncate/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/truncate" storybookUrl="/?path=/story/components-truncate--default" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Truncate`}</h3>
        <p>{`Truncation, or shortening, is typically used for text or links that exceed the size of their container.
The Truncate component does all of the heavy lifting for you. You'll need to wrap the long
text with Truncate, and wrap the Truncate component with an element with a set width. This will cut off the text based on
the parent container's width, and append an ellipsis (`}<inlineCode parentName="p">{`...`}</inlineCode>{`) at the end.`}</p>
        <p>{`Good use cases for truncation include:`}</p>
        <ul>
          <li parentName="ul">{`Breadcrumbs`}</li>
          <li parentName="ul">{`Long URL links`}</li>
        </ul>
        <p>{`Truncation should `}<strong>{`NOT`}</strong>{` be used on page headers, titles, labels, error messages, validation messages,
notifications, or SIDs.`}</p>
        <p>{`Truncation can only happen on single lines of text and will never work on multiple lines. If you're looking for multiline
truncation, that will need to be controlled with JavaScript.`}</p>
        <h4>{`Accessibility`}</h4>
        <p>{`By default, Truncate has no accessibility concerns. While using Truncate, it is up to you to manage the resulting
accessibility implications. You may want to consider how Truncate affects readability since it will visually hide
information on your page.`}</p>
        <h2>{`Examples`}</h2>
        <h3>{`Basic Truncate`}</h3>
        <p>{`Truncate must be used in a container with a set width. Without this container Truncate will have no effect since it
spans the full width of a container.`}</p>
        <LivePreview scope={{
          Truncate,
          Box,
          Text
        }} mdxType="LivePreview">
  {`<Box maxWidth="size30">
  <Text as="p">
    <Truncate>Black Lives Matter. We stand with the Black community.</Truncate>
  </Text>
</Box>`}
        </LivePreview>
        <h3>{`Truncate URL`}</h3>
        <LivePreview scope={{
          Truncate,
          Anchor,
          Box
        }} mdxType="LivePreview">
  {`<Box maxWidth="size30">
  <Anchor href="/getting-started/about-paste">
    <Truncate>https://paste.twilio.design/getting-started/about-paste</Truncate>
  </Anchor>
</Box>`}
        </LivePreview>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use Truncate to shorten text that doesn't fit in its parent container" mdxType="Do">
    <Box maxWidth="size20" padding="space60" mdxType="Box">
      <Text as="p" mdxType="Text">
        <Truncate mdxType="Truncate">Some very long text to truncate</Truncate>
      </Text>
    </Box>
  </Do>
  <Dont title="Don't" body="Don't use Truncate to shorten page headings, titles, labels, error messages, validation messages,
    or notifications" mdxType="Dont">
    <Alert variant="error" mdxType="Alert">
      <Box maxWidth="size20" mdxType="Box">
        <Text as="span" mdxType="Text">
          <Truncate mdxType="Truncate">
            Your account balance is low and your credit card has expired. As a result we have suspended your account
          </Truncate>
        </Text>
      </Box>
    </Alert>
  </Dont>
        </DoDont>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/truncate - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Truncate} from '@twilio-paste/truncate';

const Component: React.FC = () => {
  return (
    <Box maxWidth="size20">
      <Text as="p">
        <Truncate>Some very long text to truncate</Truncate>
      </Text>
    </Box>
  );
};
`}</code></pre>
        <h4>{`Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      